import Component from 'vue-class-component'

import BasePage from '@/pages/Base'

import MembershipsService from '@/services/UserSettings'

@Component({})
export default class ManageLimitation extends BasePage {
  constructor() {
    super()
  }

    tableMemberships: any = {
      items: [],
      loading: false,
      headers: [
        {
          text: 'Role',
          value: 'role',
          sortable: false
        },
        {
          text: 'Limitation by URL',
          value: 'by_url',
          sortable: false
        },
        {
          text: 'Limitation to My Products',
          value: 'my_products',
          sortable: false
        }
      ],
      pagination: {
        rowsPerPage: -1
      }
    }

    mounted(){
      this.getMembershipsData()
    }

    async getMembershipsData(){
      try{
        this.tableMemberships.loading = true

        const response = await MembershipsService.getMemberships()
        const resPlus = await MembershipsService.getLocalResellerPlus()

        for (const memberships of response.data.data){
          if (memberships.attributes.limit != null)
            this.tableMemberships.items.push(memberships.attributes)
        }

        const membershipsPlus = resPlus.data.data
        this.tableMemberships.items.push(membershipsPlus.attributes)

      } catch(error){
        this.catchHandler(error)
      } finally {
        this.tableMemberships.loading = false
      }
    }

    async saveLimit(data: any = {}){
      const limit_update = {
        limit : data.limit
      }
      try{
        const response = await MembershipsService.updateMembershipLimit(data.id, limit_update)

        if (response.data.success)
          this.showSnackbar({
            text: 'Updated Limitation Successfully',
            color: 'green',
            timeout: 2000
          })
      } catch(error) {
        this.catchHandler(error)
      }
    }

}
